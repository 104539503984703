/** @jsx jsx */
import { jsx, Spinner, Text, Message, Card, Badge } from "theme-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { get, noop } from "sites-common/utils/lodash";
import {
  doFormatShortDate,
  formatSys2OdooDate,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { containerStyles } from "sites-common/utils/fabricStyles";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { DefaultButton } from "office-ui-fabric-react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import PropTypes from "prop-types";

const collection = `PreceptorAnnouncements`;
const storeAs = "pcptA2ia";
const listenerSettings1 = {
  collection,
  orderBy: [["date_added", "desc"]],
  storeAs,
};
const today = formatSys2OdooDate(new Date());

const formFields = [
  {
    name: "date_added",
    label: "Date Added",
    type: "date",
  },
  {
    name: "type",
    label: "Text Type",
    type: "select",
    options: [
      { name: "html", label: "HTML" },
      { name: "text", label: "Plain Text" },
    ],
  },
  {
    name: "note",
    label: "Note",
    props: { multiline: true, rows: 15 },
  },
];

const RenderAnnouncement = ({ a }) => {
  const firestore = useFirestore();

  const [editMode, { setTrue: setEditMode, setFalse: clearEditMode }] =
    useMyBoolean(false);

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      const { note, type = "blank" } = data;
      firestore
        .set(
          `${collection}/${a.id}`,
          { note, type, date_added: today },
          { merge: true }
        )
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [a.id, firestore]
  );

  if (editMode) {
    return (
      <Message>
        <DynamicForm
          formFields={formFields}
          defaultValues={a}
          onSubmit={onSubmit}
          onDiscard={clearEditMode}
        />
      </Message>
    );
  }

  return (
    <div sx={{ m: 3, p: 3 }}>
      <DefaultButton
        sx={{ float: "right" }}
        text="edit"
        iconProps={{ iconName: "Edit" }}
        onClick={setEditMode}
      />
      <div>
        <Text variant="description">{doFormatShortDate(a.date_added)}</Text>
        <Badge variant="inverted" sx={{ ml: 2, mr: 4 }}>
          {a.type === "html" ? "HTML" : "Plain Text"}
        </Badge>
      </div>
      <hr />
      <Card
        sx={{
          boxShadow: "0px 3px 6px #00000029",
          opacity: 1,
          borderRadius: "2px",
          borderColor: "background",
          backgroundColor: "background",
          p: 2,
        }}
      >
        {a.type === "html" ? (
          <div dangerouslySetInnerHTML={{ __html: a.note }} />
        ) : (
          <div>{a.note}</div>
        )}
      </Card>
    </div>
  );
};

RenderAnnouncement.propTypes = {
  a: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
    date_added: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

const NewRecord = ({ onDiscard }) => {
  const firestore = useFirestore();

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      const { note, date_added } = data;
      firestore
        .add(`${collection}`, { note, date_added })
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [firestore]
  );

  return (
    <Message>
      <DynamicForm
        formFields={formFields}
        defaultValues={{ date_added: today }}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      />
    </Message>
  );
};

NewRecord.propTypes = {
  onDiscard: PropTypes.func,
};
NewRecord.defaultProps = {
  onDiscard: noop,
};
function AnnouncementsBoard({ onlyLive }) {
  const firestore = useFirestore();
  const announcements = useSelector((state) =>
    get(state.firestore.ordered, [storeAs], [])
  );
  const [limit] = useState(20);
  const [add, { setTrue: setAdd, setFalse: clearAdd }] = useMyBoolean(false);

  useEffect(() => {
    const listenerSettings = { ...listenerSettings1, limit };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore, limit]);

  return (
    <div>
      <Text style={containerStyles.flexcenter} variant="header">
        Preceptor Announcements Board
      </Text>
      <Text style={containerStyles.flexcenter} variant="subtitle">
        Admin Console
      </Text>
      <div style={containerStyles.flexcenter}>
        Link to Live Page:&nbsp;
        <a target="_blank" href="/trainer/live">
          Click Here
        </a>
      </div>
      {announcements === undefined && <Spinner />}
      {announcements !== undefined && (
        <div>
          {!onlyLive && (
            <div>
              {!add && (
                <DefaultButton
                  sx={{ mt: 4 }}
                  iconProps={{ iconName: "Add" }}
                  text="Add New Announcement"
                  onClick={setAdd}
                />
              )}
              {add && <NewRecord onDiscard={clearAdd} />}
            </div>
          )}
          {announcements.map((a) => {
            return (
              <div sx={{ my: 3 }}>
                <RenderAnnouncement a={a} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

AnnouncementsBoard.propTypes = {
  onlyLive: PropTypes.bool,
};
AnnouncementsBoard.defaultProps = {
  onlyLive: false,
};

export default AnnouncementsBoard;
