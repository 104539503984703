/** @jsx jsx */
import { jsx } from "theme-ui";

import DefaultLayout from "../../layouts/default";
import AnnouncementsBoard from "../../components/Trainer/AnnouncementsBoard/admin";

function AbhyasiRegistrationFormPage() {
  return (
    <DefaultLayout>
      <AnnouncementsBoard onlyLive />
    </DefaultLayout>
  );
}

export default AbhyasiRegistrationFormPage;
